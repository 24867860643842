import './styles/App.css';
import nftLogoSmall from './assets/frootinvaders-logo-small.png';
import nftLogo from './assets/frootinvaders-logo.png';
import featureBG from './assets/feature-bg.jpg';
import NFT1 from './assets/frootling-1.jpg';
import NFT2 from './assets/frootling-2.jpg';
import NFT3 from './assets/frootling-3.jpg';
import Unminted from './assets/unminted-frootling.png';

import immortalsAddresses from './whitelists/immortalsAddresses.json';
import frootFrensAddresses from './whitelists/frootFrensAddresses.json';

import { ethers } from "ethers";

import React, { useEffect, useState } from "react";

import { Rings,MutatingDots } from  'react-loader-spinner'

import nftABI from './utils/FrootInvaders.json';

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

const immortalsSaleLive = true;
const frootFrensSaleLive = true;
const publicSaleLive = true;

const immortalPrice = 0.003;
const publicPrice = 0.005;

const MAX_SUPPLY = 999;

const CONTRACT_ADDRESS = "0xD7B65d5151c497302a5903C4E236892c2C7B6227";

const App = () => {

  const [currentAccount, setCurrentAccount] = useState(false);
  const [correctNetwork, setCorrectNetwork] = useState(false);
  const [amountToMint,setAmountToMint] = useState(1);

  const [userLevel,setUserLevel] = useState('public');

  const [mintStatus,setMintStatus] = useState('');
  const [walletStatus,setWalletStatus] = useState('wallet-disconnected');
  const [noticeText,setNoticeText] = useState(false);

  const [ethAddress,setETHAddress] = useState(null);

  const [isSoldOut,setIsSoldOut] = useState(false);

  const [walletTokens, setWalletTokens] = useState(null);
  const [walletTokenCount, setWalletTokenCount] = useState(0);


  const checkIfWalletIsConnected = async () => {
    const { ethereum } = window;

    if (!ethereum) {
        setNoticeText("You need to install Metamask! If you're on mobile, open this page using the Metamask app.");
        return;
      } 
    else {
        //setNoticeText("We have the ethereum object.");
      }

    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length !== 0) {
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      // Get .eth address
      const provider = new ethers.providers.Web3Provider(ethereum);
      const ethAddress = await provider.lookupAddress(accountCaseSensitive);

      setCurrentAccount(accountCaseSensitive);
      setETHAddress(ethAddress);
      buttonSetUserLevel(accountCaseSensitive);

      setWalletStatus("wallet-connected");
      } 
    else {
      //setNoticeText("No authorized account found");
      }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        setNoticeText("You need to install Metamask! Or, if you're on mobile and Metamask is already installed, you must open this page using the browser within the Metamask app.");
        return;
      }

      setWalletStatus("connecting-wallet");

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      const accountCaseSensitive = ethers.utils.getAddress(accounts[0]);

      setCurrentAccount(accountCaseSensitive);
      buttonSetUserLevel(accountCaseSensitive);
      setWalletStatus("wallet-connected");
      setNoticeText(false);

      // Is returning user's wallet already connected?
      //setupEventListener() 
      }
    catch (error) {
      setNoticeText(error.message);
      setWalletStatus("wallet-disconnected");
      setMintStatus("");
      }
    }
  
  // Checks if wallet is connected to the correct network
  const checkCorrectNetwork = async () => {
    
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    //console.log('Connected to chain:' + chainId)

    /* if (chainId !== '0x1') { rinkeby */
    /* if (chainId === '0x1') { mainnet */
    if (chainId === '0x1') { // mainnet
    setCorrectNetwork(true);
    } else {
        setCorrectNetwork(false);
        setNoticeText("Connect to Ethereum Mainnet and reload the page!");
    }
  }
      

  /*const setupEventListener = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, SkullKids.abi, signer);

        // THIS IS THE MAGIC SAUCE.
        // This will essentially "capture" our event when our contract throws it.
        // If you're familiar with webhooks, it's very similar to that!
        connectedContract.on("NewEpicNFTMinted", (from, tokenId) => {
          console.log(from, tokenId.toNumber())
          alert(`Hey there! We've minted your NFT and sent it to your wallet. It may be blank right now. It can take a max of 10 min to show up on OpenSea. Here's the link: https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`)
        });

        console.log("Setup event listener!")

      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error)
    }
  }*/


  /* Mint Functions */
//  const godsMint = () => mintSkullKid('god',getAddressProof(godsAddresses));
  const immortalsMint = () => mintSkullKid('immortal',getAddressProof(immortalsAddresses));
  const frootFrensMint = () => mintSkullKid('frootFren',getAddressProof(frootFrensAddresses));
  const publicMint = () => mintSkullKid('public');

  const mintSkullKid = async (_accessLevel,_proof=null) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let _price;

        /*if (_accessLevel === 'god') { _price = immortalGodPrice; }
        else*/ if (_accessLevel === 'immortal') { _price = immortalPrice; }
        else { _price = publicPrice; }
        
        let _mintTotal = (_price * 1000000 * amountToMint / 1000000).toString();
        const _options = {value: ethers.utils.parseEther(_mintTotal)}

        //console.log(_proof);
        //setNoticeText("Opening wallet...");

        setMintStatus("minting");

        let nftTxn;

        /*if (_accessLevel === 'god') {
          nftTxn = await connectedContract.godsMint(amountToMint,_proof,_options);
        }
        else*/ if (_accessLevel === 'immortal') {
          nftTxn = await connectedContract.immortalsMint(amountToMint,_proof,_options);
        }
        else if (_accessLevel === 'frootFren') {
          nftTxn = await connectedContract.frootFrensMint(amountToMint,_proof,_options);
        }
        else {
          nftTxn = await connectedContract.publicMint(amountToMint,_options);
        }
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        
        setMintStatus("minted");
        setNoticeText(`Success!<br />View your Frootlings Collection below!</a>`)

        getMints(true);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      if (error.code === 'INSUFFICIENT_FUNDS') {
        setNoticeText('Insufficient funds to complete this transaction!')
        setMintStatus("error");
        }
      else if (error.code === 'UNPREDICTABLE_GAS_LIMIT') {
        setNoticeText(`Whoops, you don't have ${getCleanUserLevel(_accessLevel)} access or the sale hasn't started!`);
        setMintStatus("error");
      }
      else {
        setNoticeText(error.message);
        setMintStatus("error");
      }
    }
  }

  const setPayoutAddresses = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.setPayoutAddresses('0x0BaAd4A35D8BFBD806A0D2829Eed25a12905b3b7','0x0BaAd4A35D8BFBD806A0D2829Eed25a12905b3b7');
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`View your transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };

  const setBaseURI = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.setBaseURI('https://api.ryps.co/frootlings/id/');
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`View your transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };

  const getBaseURI = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        const baseURI = await connectedContract.baseURI();

        setNoticeText(`Base URI: ${baseURI}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

  const withdrawFromContract = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        //setNoticeText("Opening wallet...")

        let nftTxn = await connectedContract.withdraw();
        
        setNoticeText("Waiting for transaction confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`All funds withdrawn! https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      } else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } catch (error) {
      //console.log(error);
      setNoticeText(error.message);
      }
  };
  /* END Mint Functions */



  /* Set Functions */
  // Passing _currentWallet to allow calling before global currentAccount is set
  const buttonSetUserLevel = (_currentWallet = currentAccount) => {
    /*if (getAddressProof(godsAddresses,_currentWallet).length !== 0) {
      setUserLevel('god');
    }
    else*/ if (getAddressProof(immortalsAddresses,_currentWallet).length !== 0) {
      setUserLevel('immortal');
    }
    else if (getAddressProof(frootFrensAddresses,_currentWallet).length !== 0) {
      setUserLevel('frootFren');
    }
    else {
      setUserLevel('public');
    }
  };
  /* END Set Functions */


  /* Get Functions */
  const getCleanUserLevel = (_userLevel = userLevel) => {
    if (_userLevel === 'god') {
      return "Immortal God";
    }
    else if (_userLevel === 'immortal') {
      return "Immortal";
    }
    else if (_userLevel === 'frootFren') {
      return "Froot Fren";
    }
    else {
      return "Public";
    }
  };
  


  /*
  ** Admin Functions
  */
  const makeSaleLive = async (saleLevel) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let nftTxn = "";

        setNoticeText(`Making ${saleLevel} sale live`);

        if (saleLevel === 'Immortals') {
          nftTxn = await connectedContract.toggleImmortalsSale();
        }
        else if (saleLevel === 'Froot Frens') {
          nftTxn = await connectedContract.toggleFrootFrensSale(true);
        }
        else if (saleLevel === 'Public') {
          nftTxn = await connectedContract.togglePublicSale(true);
        }

        setNoticeText("Waiting for transaction confirmation...");
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`See transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

  const getTotalSupply = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        const _latestMint = parseInt(await connectedContract.totalSupply()) + 200;

        setNoticeText(`Total Supply: ${_latestMint}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }
  /* END Admin Functions */



  /* Merkle Functions */

  const makeImmortalsSaleLive = () => makeSaleLive('Immortals');
  const makeFrootFrensSaleLive = () => makeSaleLive('Froot Frens');
  const makePublicSaleLive = () => makeSaleLive('Public');


  //const setGodsMerkleRoot = () => setMerkleRoot('Gods');
  const setImmortalsMerkleRoot = () => setMerkleRoot('Immortals');
  const setFrootFrensMerkleRoot = () => setMerkleRoot('Froot Frens');

  const setMerkleRoot = async (saleLevel) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        let nftTxn = "";

        setNoticeText(`Setting ${saleLevel} Root Hash...`);

        /*if (saleLevel === 'Gods') {
          nftTxn = await connectedContract.setGodsMerkleRoot(getRootHash('Gods',godsAddresses));
        }
        else*/ if (saleLevel === 'Immortals') {
          nftTxn = await connectedContract.setImmortalsMerkleRoot(getRootHash('Immortals',immortalsAddresses));
        }
        else if (saleLevel === 'Froot Frens') {
          nftTxn = await connectedContract.setFrootFrensMerkleRoot(getRootHash('Froot Frens',frootFrensAddresses));
        }
        
        setNoticeText("Waiting for confirmation...")
        await nftTxn.wait();
        /*console.log(nftTxn);
        console.log(nftTxn.events);*/
        setNoticeText(`${saleLevel} Root Hash Set! https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);

      }
      else {
        setNoticeText("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      setNoticeText(error.message);
    }
  }

    const getRootHash = (listName,vipAddresses) => {    
      const vipMerkleTree = getVIPMerkleTree(vipAddresses);
      const vipRootHash = vipMerkleTree.getRoot().toString('hex');
      setNoticeText(`${listName} Root Hash: 0x${vipRootHash}`);
      return `0x${vipRootHash}`;
    };

  //const getGodsRootHash = () => getRootHash('Immortal Gods',godsAddresses);
  const getImmortalsRootHash = () => getRootHash('Immortals',immortalsAddresses);
  const getFrootFrensRootHash = () => getRootHash('Froot Frens',frootFrensAddresses);
  
  //const getAddressProofGods = () => {console.log("Immortal Gods Proof:");getAddressProof(godsAddresses)};
  const getAddressProofImmortals = () => {console.log("Immortals Proof:");getAddressProof(immortalsAddresses)};
  const getAddressProofFrootFrens = () => {console.log("Froot Frens Proof:");getAddressProof(frootFrensAddresses)};

  //const getREMIXAddressProofImmortals = () => {console.log("REMIX Immortals Proof:");getAddressProof(godsAddresses,"0x5B38Da6a701c568545dCfcB03FcB875f56beddC4")};

  const getVIPMerkleTree = (vipAddresses) => {
    const hashedVIPAddresses = vipAddresses.map(addr => keccak256(addr));
    return new MerkleTree(hashedVIPAddresses, keccak256, { sortPairs: true });
  };

  // Passing currentAccount to allow calls before global set
  const getAddressProof = (vipAddresses,_currentWallet = currentAccount) => {
    const vipMerkleTree = getVIPMerkleTree(vipAddresses);

    const hashedAddress = keccak256(_currentWallet);
    const proof = vipMerkleTree.getHexProof(hashedAddress);
    //console.log(JSON.stringify(proof));

    return proof;
  };
  /* END Merkle Functions */


  // Display connected wallet whenever returning to site
  /*useEffect(() => {
    checkIfWalletIsConnected();
    checkCorrectNetwork();
  })*/


  /* Render Admin */
  const renderAdminButtons = (
    <div className="admin-buttons">
      <h2>Admin Functions</h2>
      <h4>All Mint Buttons</h4>
      <p>
        {/*<button onClick={godsMint} className="cta-button connect-wallet-button">Immortal Gods Mint {immortalGodPrice}</button>*/}
        <button onClick={immortalsMint} className="cta-button connect-wallet-button">Immortals Mint {immortalPrice}</button>
        <button onClick={frootFrensMint} className="cta-button connect-wallet-button">Froot Frens Mint {publicPrice}</button>
        <button onClick={publicMint} className="cta-button connect-wallet-button">Public Mint {publicPrice}</button>
        </p>
      <h4>Root Hashes</h4>
      <p>
        {/*<button onClick={getGodsRootHash} className="cta-button connect-wallet-button">Get Gods Root Hash</button> */}
        <button onClick={getImmortalsRootHash} className="cta-button connect-wallet-button">Get Immortals Root Hash</button>
        <button onClick={getFrootFrensRootHash} className="cta-button connect-wallet-button">Get Froot Frens Root Hash</button></p>
      <h4>Proofs</h4>
      <p>
        {/*<button onClick={getAddressProofGods} className="cta-button connect-wallet-button">My Immortal Gods Proof</button>*/}
        <button onClick={getAddressProofImmortals} className="cta-button connect-wallet-button">My Immortals Proof</button>
        <button onClick={getAddressProofFrootFrens} className="cta-button connect-wallet-button">My Froot Frens Proof</button>
        {/*<button onClick={getREMIXAddressProofImmortals} className="cta-button connect-wallet-button">My REMIX Immortals Proof</button>*/}
        </p>
      <h4>Make Sales Live</h4>
      <p>
        <button onClick={makeImmortalsSaleLive} className="cta-button connect-wallet-button">Make Immortals Sale Live</button>
        <button onClick={makeFrootFrensSaleLive} className="cta-button connect-wallet-button">Make Froot Frens Sale Live</button>
        <button onClick={makePublicSaleLive} className="cta-button connect-wallet-button">Make Public Sale Live</button>
        </p>
      <h4>Set Merkle Roots</h4>
      <p>
        {/*<button onClick={setGodsMerkleRoot} className="cta-button connect-wallet-button">Set Immortal Gods Merkle Root</button>*/}
        <button onClick={setImmortalsMerkleRoot} className="cta-button connect-wallet-button">Set Immortals Merkle Root</button>
        <button onClick={setFrootFrensMerkleRoot} className="cta-button connect-wallet-button">Set Froot Frens Merkle Root</button>
        </p>
      <h4>Payouts</h4>
      <p>
        <button onClick={setPayoutAddresses} className="cta-button connect-wallet-button">Set Payout Addresses</button>
        <button onClick={withdrawFromContract} className="cta-button connect-wallet-button">Withdraw All Funds</button>
        </p>
      <p>
        <button onClick={buttonSetUserLevel} className="cta-button connect-wallet-button">Set User Level</button>
        <button onClick={setBaseURI} className="cta-button connect-wallet-button">Set Base URI</button>
        <button onClick={getBaseURI} className="cta-button connect-wallet-button">Get Base URI</button>
        <button onClick={getTotalSupply} className="cta-button connect-wallet-button">Get totalSupply()</button>
      </p>
      </div>
  );  
  /* END Render Admin */



  /* Render Frontend */
  const renderSalesInactive = () => (
    <>
      <div className="entrance">
        <div className={"mint-columns " + userLevel}>
          {/*<div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Immortal Gods Price
                <div className="userLevelMintPriceWrap">{immortalGodPrice}</div>
              </div>
            </div>
          </div>*/}
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Immortals Price
                <div className="userLevelMintPriceWrap">{immortalPrice}</div>
              </div>
            </div>
          </div>
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Public Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
              </div>
            </div>
          </div>
        </div>
        <button disabled>Minting 04/27!</button>
        </div>
    </>
  );
  
  
  const renderWrongNetwork = (
    <div className="loading-wrong-network">
      <div className="loading-wrong-network-rings">
        <Rings color="#ff00fb" height={120} width={120} /><br />
      </div>
      <div className="loading-wrong-network-text">{noticeText}</div>
    </div>
  );
  
  const renderNotConnectedContainer = () => (
    <>
      <div className="entrance">
        <div className="loading"><MutatingDots color="#ff00fb" secondaryColor="#ff00fb" height={120} width={120} /></div>
        {renderWrongNetwork}
        <div className={"mint-columns " + userLevel}>
          {/*<div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Immortal Gods Price
                <div className="userLevelMintPriceWrap">{immortalGodPrice}</div>
                {immortalsSaleLive === true ? 'Sale Open' : 'Opening Soon'}
              </div>
            </div>
          </div>*/}
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                Immortals Price
                <div className="userLevelMintPriceWrap">{immortalPrice}</div>
                {immortalsSaleLive === true ? 'Sale Open' : 'Opening Soon'}
              </div>
            </div>
          </div>
          <div className="column-price column">
            <div className="mintPrice">
              <div className="userLevelMintPrice">
                {/*Froot Frens Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
                {frootFrensSaleLive === true ? 'Sale Open' : 'Opening Soon'}*/}
                Public Price
                <div className="userLevelMintPriceWrap">{publicPrice}</div>
                {publicSaleLive === true ? 'Sale Open' : 'Opening Soon'}
              </div>
            </div>
          </div>
        </div>
        <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
        <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
        </div>
    </>
  );

  const renderNotConnectedButton = () => (
    <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button>
  );

  const renderSoldOutContainer = () => (
    <>
      <div className="sold-out">
        Sold Out!
      </div>
    </>
  );

  const renderConnectedButton = () => (
    <><a href="#collection" className="nav-item">Your Frootlings</a> <a href="#mint" className="nav-item">Mint</a> <button className="cta-button connect-wallet-button logged-in">{renderShortAddress()}</button></>
  );

  const renderNotConnectedNav = () => (
    <><a href="https://mint.frootinvaders.com" className="nav-item">Mint Frootlings</a> <a href="https://play.frootinvaders.com" target="_blank" rel="noreferrer" className="nav-item">Play Froot Invaders</a> <a href="https://mint.badfroot.com" target="_blank" rel="noreferrer" className="nav-item">Mint SkullKids</a> <button onClick={connectWallet} className="cta-button connect-wallet-button">Connect Wallet</button></>
  );

  const renderMintCountSelect = (
    <div className="mintCountWrapper">
      <select name="mintCount" id="mintCount" className="mintCount" onChange={event => setAmountToMint(event.target.value)}>
        <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option><option value="6">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option><option value="10">10</option>
        <option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option>
        <option value="21">21</option><option value="22">22</option><option value="23">23</option><option value="24">24</option><option value="25">25</option>
        </select>
      </div>
  );

  const renderTotal = () => {
    /*if (userLevel === 'god') { return immortalGodPrice * 1000000 * amountToMint / 1000000; }
    else*/ if (userLevel === 'immortal') { return immortalPrice * 1000000 * amountToMint / 1000000; }
    else {  return publicPrice * 1000000 * amountToMint / 1000000; }
  };

  const getUserLevelPrice = (_userLevel = userLevel) => {
    /*if (_userLevel === 'god') {
      return immortalGodPrice;
    }
    else*/ if (_userLevel === 'immortal') {
      return immortalPrice;
    }
    else {
      return publicPrice;
    }
  };

  const renderMintButton = (
    /*(userLevel === 'god' ?
      <button onClick={godsMint} className="cta-button connect-wallet-button">Mint Now!</button>
      : */(userLevel === 'immortal' ?
        <button onClick={immortalsMint} className="cta-button connect-wallet-button">Mint Now!</button>
        : (userLevel === 'frootFren' ?
          <button onClick={frootFrensMint} className="cta-button connect-wallet-button">Mint Now!</button>
          : <button onClick={publicMint} className="cta-button connect-wallet-button">Mint Now!</button>
          )
        )
    //)
  );

  const renderMintButtons = (
    <div className="mintWrapper">
      <div className="loading"><MutatingDots color="#ff00fb" secondaryColor="#ff00fb" height={120} width={120} /></div>
      {
        (
          (immortalsSaleLive && (userLevel === 'god' || userLevel === 'immortal')) ||
          (frootFrensSaleLive && (userLevel === 'frootFren')) ||
          publicSaleLive ? 
          <>
          <div className={"mint-columns " + userLevel}>
            <div className="column-select column">{renderMintCountSelect}</div>
            <div className="column-price column">
              <div className="mintPrice">
                {(userLevel === 'god' || userLevel === 'immortal' ? <><div className="mintPriceSlashed">Price<del>{publicPrice}</del></div><hr /></> : '')}
                <div className="userLevelMintPrice">
                  {(getCleanUserLevel() !== 'Public' ? getCleanUserLevel() : '')} Price
                  <div className="userLevelMintPriceWrap">{getUserLevelPrice()}</div>
                </div>
              </div>
            </div>
            <div className="column-total column">{renderTotal()}</div>
          </div>
          <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
          {renderMintButton}
          </> : 
            <div className="entrance">
            <div className="loading"><MutatingDots color="#ff00fb" secondaryColor="#ff00fb" height={120} width={120} /></div>
            {renderWrongNetwork}
            <div className={"mint-columns " + userLevel}>
              {/*<div className="column-price column">
                <div className="mintPrice">
                  <div className="userLevelMintPrice">
                    Immortal Gods Price
                    <div className="userLevelMintPriceWrap">{immortalGodPrice}</div>
                    {immortalsSaleLive === true ? 'Sale Open' : 'Opening Soon'}
                  </div>
                </div>
              </div>*/}
              <div className="column-price column">
                <div className="mintPrice">
                  <div className="userLevelMintPrice">
                    Immortals Price
                    <div className="userLevelMintPriceWrap">{immortalPrice}</div>
                    {immortalsSaleLive === true ? 'Sale Open' : 'Opening Soon'}
                  </div>
                </div>
              </div>
              <div className="column-price column">
                <div className="mintPrice">
                  <div className="userLevelMintPrice">
                    Froot Frens Price
                    <div className="userLevelMintPriceWrap">{publicPrice}</div>
                    {frootFrensSaleLive === true ? 'Sale Open' : 'Opening Soon'}
                  </div>
                </div>
              </div>
            </div>
            <div className="notice">{(noticeText !== false ? <div className="notice-text" dangerouslySetInnerHTML={{__html: noticeText}}></div> : "")}</div>
            <div className="saleClosed">The {getCleanUserLevel()} sale isn't open yet!{userLevel === 'public' ? <><br />Get on the Froot Frens Allowlist! <a href="https://twitter.com/jeffSARRIS/status/1520120640023453698?s=20&t=5bEdtmuMyFeVU-31gf_SOA" target="_blank" rel="noreferrer">Details Here</a></> : <></>}</div>
            </div>
        )
      }
      </div>
  );

  const renderMintUI = () => (
    <>
    <div className="entrance">
    {renderWrongNetwork}
    {renderMintButtons}
    </div>
    </>
  );


  const checkIfSoldOut = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
        
        var totalMinted = await connectedContract.totalSupply();

        if (parseInt(totalMinted.toNumber()) >= MAX_SUPPLY) { setIsSoldOut(true); }
        
      }
      else {
        console.log("Ethereum object doesn't exist!");
      }
    } 
    catch (error) {
      console.log(error.message);
    }
  }

  const getMints = async (forceReload = false) => {

    if (currentAccount && (walletTokens === null || forceReload)) {
      try {
        const { ethereum } = window;

        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, nftABI.abi, signer);
          
          const skullkidsTokens = await connectedContract.tokensOfOwner(currentAccount);

          // Amara
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x355E64DD4bEB2DdC8136b1C17bf18611320AC5B2');

          // Froot Stand
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x91c1A7cc16dB55F861559EC515eb965c03A9Bd1A');

          // Dellyjoe
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xC01eBC09ec7D2432a97B0094F84EeC2029ED8169');

          // Luis
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xB74D382a9aAc99a33b1129A234ABe3938bA6dECb');

          // ZombieBits
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0xcfc80e71d63ef4b30dc38507196616ef625dfdf5');

          // Madkat
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x546af69a6d39d93126ef36beb006a824b25a60c6');

          // EquinsuOcha
          //const skullkidsTokens = await connectedContract.tokensOfOwner('0x913D5f297095b3E32d1AFF25D0f7c6F3E8D61d89');
          
          //if (skullkidsTokens.length > 0) {
            setWalletTokenCount(skullkidsTokens.length);

            var nftHTML = '';
            var count = 0;
            var prefix = 'Frootling';
            var levelClass = '';
            var tokensPerPage = 28;

            // Display Images Loop
            for (let i = skullkidsTokens.length - 1;i >= 0; i--) {
              count++;
              levelClass = '';

              if (count === 0) { levelClass = "None"; }
              else if (count === 1) { levelClass = "SkullKid"; }
              else if (count === 5) { levelClass = "Phenom"; }
              else if (count === 20) { levelClass = "Hero"; }
              else if (count === 50) { levelClass = "Icon"; }
              else if (count === 100) { levelClass = "Legend"; }
              else if (count === 500) { levelClass = "Mythic"; }

              let tokenMetadataURI = 'https://api.ryps.co/frootlings/id/' + skullkidsTokens[i].toNumber();

              const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json());

              nftHTML += `<div class="nft ${levelClass}">`;
              nftHTML += `<a href="https://opensea.io/assets/${CONTRACT_ADDRESS}/${skullkidsTokens[i].toNumber()}" class="anchor" target="_blank" rel="noreferrer">`;
              nftHTML += `<img src="https://api.ryps.co/frootlings/images_600/${tokenMetadata['dna']}.png" alt=${tokenMetadata['name']} />`;
              nftHTML += `<div class="nft-title"><span>${prefix}</span> #${skullkidsTokens[i].toNumber()}</div>`;
              nftHTML += '</a>';
              nftHTML += '</div>';

              if (count >= tokensPerPage) { break; }
            }

            let j = 0;
            var fillCount = getWalletLevel('next-count',skullkidsTokens.length) > 1 ? getWalletLevel('next-count',skullkidsTokens.length) : 4;

            while (count < tokensPerPage && j < fillCount) {
              count++;
              j++;
              levelClass = '';

              if (count === 1) { levelClass="SkullKid"; }
              else if (count === 5) { levelClass="Phenom"; }
              else if (count === 20) { levelClass="Hero"; }
              else if (count === 50) { levelClass="Icon"; }
              else if (count === 100) { levelClass = "Legend"; }
              else if (count === 500) { levelClass = "Mythic"; }
              
              nftHTML += `<div class="nft  ${levelClass} unminted">`;
                nftHTML += '<div class="anchor">';
                  nftHTML += `<img src=${Unminted} alt="SkullKid" />`;
                  nftHTML += `<div class="nft-title"><span>${prefix}</span> #----</div>`;
                nftHTML += '</div>';
              nftHTML += '</div>';
            }

            if (skullkidsTokens.length > 0) {
              nftHTML += '<div class="view-more"><a href="https://opensea.io/account?search[resultModel]=ASSETS&search[sortBy]=CREATED_DATE&search[query]=skullkid&search[sortAscending]=false" target="_blank" rel="noreferrer">';
              nftHTML += 'View on OpenSea';
              nftHTML += '</a></div>';
            }

            setWalletTokens(nftHTML);
        }
        else {
          console.log("Ethereum object doesn't exist!");
        }
      } 
      catch (error) {
        console.log(error.message);
      }
    }
  };

  const awaitGetMints = async () => {
    await getMints();
  };

  useEffect(() => {
    checkIfWalletIsConnected();
    checkCorrectNetwork();
    checkIfSoldOut();
    awaitGetMints();
  });

  const renderShortAddress = () => {
    if (ethAddress !== null) {
      return ethAddress;
    }
    else {
      return currentAccount.toString().substr(0,6) + '\u2026' + currentAccount.toString().substr(-4)
    }
  };

  const getWalletLevel = (getLevel = 'current',_walletTokenCount = walletTokenCount) => { // Pass walletTokenCount when state hasn't been set
    var _level = '';

    var levelNames = [
      [ 500,"SkullKid Mythic" ],
      [ 100,"SkullKid Legend" ],
      [ 50,"SkullKid Icon" ],
      [ 20,"SkullKid Hero" ],
      [ 5,"SkullKid Phenom" ],
      [ 1,"SkullKid" ],
      [ 0,"None" ],
    ];

    for (let i = 0; i < levelNames.length; i++) {
      if (_walletTokenCount >= levelNames[i][0]) {
        if (getLevel === 'current') {
          _level = levelNames[i][1];
        }
        else if (getLevel === 'next') {
          if (i > 0) {
            let nextLevelIndex = i-1;
            _level = `${levelNames[nextLevelIndex][0]-_walletTokenCount} away from ${levelNames[nextLevelIndex][1]}`;
          }
          else {
            _level = false;// No higher level
          }
        }
        else if (getLevel === 'next-count') {
          if (i > 0) {
            let nextLevelIndex = i-1;
            _level = `${levelNames[nextLevelIndex][0]-_walletTokenCount}`;
          }
          else {
            _level = false;// No higher level
          }
        }
        return _level;
      }
    }
  };


  const showWalletTokens = (
    <div className="wallet-token-wrapper" id="collection">
      <div className="wallet-token-header">
        <h2><small>Your</small> Frootlings <small className="bottom-small">Collection</small></h2>
        <div className="wallet-details">
          <div className="wallet-address"><strong>Wallet:</strong> {renderShortAddress()}</div>
          <div className="wallet-status"><strong>Status:</strong> {(getCleanUserLevel() !== 'Public' ? getCleanUserLevel() : 'Froot Fren')}</div>
          <div className="wallet-level"><strong>More:</strong> <a href="https://mint.badfroot.com" target="_blank">SkullKids: Generations</a></div>
          {/*<div className="wallet-level"><strong>Level:</strong> {getWalletLevel()}
            <div className="wallet-next-level">{getWalletLevel('next') !== false ? '(' + getWalletLevel('next') + ')' : null}</div>
            </div>*/}
        </div>
      </div>
      {/*<div className={`wallet-level-bar ${getWalletLevel()}`}>
        <div className="arrow-steps clearfix">
          <div className="step-wrap">
            <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid
              <small>(1+)</small>
            </div>
          </div>
          <div className="step-wrap">
          <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid Phenom
              <small>(5+)</small>
            </div>
          </div>
          <div className="step-wrap">
          <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid Hero
              <small>(20+)</small>
            </div>
          </div>
          <div className="step-wrap">
          <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid Icon
              <small>(50+)</small>
            </div>
          </div>
          <div className="step-wrap">
          <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid Legend
              <small>(100+)</small>
            </div>
          </div>
          <div className="step-wrap">
          <div className="level-icon"></div>
            <div className="step"><span></span></div>
            <div className="level-title">
              SkullKid Mythic
              <small>(500+)</small>
            </div>
          </div>
			  </div>
      </div>*/}
      <div className="wallet-quantity">{walletTokenCount !== 1 ? walletTokenCount + ' Frootlings' : 'Frootling'}</div>
      <div className="wallet-tokens" dangerouslySetInnerHTML={{__html: walletTokens}}></div>
    </div>
  );


  return (
    <div className={`App ${walletStatus} ${mintStatus}${correctNetwork === false ? ' wrong-network' : immortalsSaleLive !== true && frootFrensSaleLive !== true && publicSaleLive !== true ? ' countdown' : ''}`} style={{ backgroundImage: `url(${featureBG})` }}>
      <div className="site-header">
        <div className="wrap">
          <div className="header-left">
            <a href="/"><img src={nftLogoSmall} className="site-logo" width="34" height="49" alt="Badfroot" /> Froot Invaders</a>
          </div>
          <div className="header-right">
            {correctNetwork === false ? '' : currentAccount === false ? renderNotConnectedNav() : renderConnectedButton()}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="header-container">
          <div className="large-logo" id="mint"><img src={nftLogo} alt="Froot Invaders" /></div>
          {isSoldOut === true ? null : immortalsSaleLive === true || frootFrensSaleLive === true || publicSaleLive === true ? currentAccount === false ? renderNotConnectedButton() : renderMintUI() : renderSalesInactive()}
        </div>
        <div className="content-section content-section-1">
        {currentAccount !== false ? showWalletTokens : undefined}
          <div className="wrap story" id="story">
          <h2>The Frootlings Story</h2>
            <p><img src={NFT1} className="align-right" alt="Frootling" />In the year two thousand plus fifteen, a new technology arrived on Planet Froot. As the Frootlings began working with the Ether, they discovered that the immense power could be harnessed in a unique way.</p>
            <p>Over the next several years, the Frootlings experimented with Ether propulsion technology. And, by the year two thousand plus twenty one, they finally achieved the impossible.</p>
            <p>They could leave their home galaxy.</p>
            <p>Ether was ever-strengthening, launching their ships deeper and deeper into the furthest reaches of the universe.</p>
            <p>That is, until the year two thousand plus twenty two when things started to go awry.</p>
            <p><img src={NFT2} className="align-left" alt="Frootling" />They found their expectation, that the power of their fuel would continue to grow, had proven to be incorrect.</p>
            <p>The Frootlings were experiencing the first rough ride of their journey. Rather than growing more powerful, their fuel (Ether) was rapidly being consumed. And thus, they had to initiate emergency procedures and land at the first opportunity.</p>
            <p>That's when they encountered the Community Milky Way and set their course for our planet.</p>
            <p>Now they're here, looking for just a little Ether to help them return to the skies once again - one Frootling at a time.</p>
            <p><img src={NFT3} className="align-right" alt="Frootling" />They don't have much to offer since, to get home, they'll need their ships fully intact. Their only solution is to sell pictures of their family and frens. Humans seem to like those things.</p>
            <p>Sponsor a Frootling to help them make their way home. As a thank you, they'll send you a picture of themselves from somewhere along their journey thus far.</p>
            <p>All it takes is a small amount of ETH (0.005) to help one of the 999 Frootlings make their way back to Planet Froot.</p>
            <p>Sponsor a Frootling today!</p>
          </div>
        </div>
        <div className="content-section content-section-2">
        <div className="wrap">
          <h2>Team</h2>
          <div className="column-wrap the-team">
            <div className="column">
              <div className="block-image"><figure><a href="https://twitter.com/thebadfroot" target="_blank" rel="noreferrer"><img src="https://badfroot.com/wp-content/uploads/sites/11/2022/01/Badfroot-Jack-Davidson-1.jpg" alt="Badfroot aka Jack Davidson" width="375" height="375" /></a></figure></div>
              <h4 id="badfroot-jack-davidson">Badfroot (Jack Davidson)</h4>
              <p><em>Artist/Creator of SkullKids</em></p>
              <p>Jack grew up on the East End of Long Island, New York. Before he became a full-time artist he did sales and marketing, sold real estate in the Hamptons, and worked part-time in a group home for underprivileged and incarcerated youth. When he learned about NFTs from one of his close friends he gave up his career in sales, dove headfirst into learning how the space worked, and eventually created SkullKids, the original 1/1s now known as SkullKids: Immortal. The generative, 10K SkullKids project is the next evolution of SkullKids and the Badfroot empire.</p>
              {/*<p>
                <a href="https://twitter.com/thebadfroot" aria-label="Twitter: https://twitter.com/thebadfroot" rel="noreferrer" target="_blank">
                  <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" focusable="false"><path d="M22.23,5.924c-0.736,0.326-1.527,0.547-2.357,0.646c0.847-0.508,1.498-1.312,1.804-2.27 c-0.793,0.47-1.671,0.812-2.606,0.996C18.324,4.498,17.257,4,16.077,4c-2.266,0-4.103,1.837-4.103,4.103 c0,0.322,0.036,0.635,0.106,0.935C8.67,8.867,5.647,7.234,3.623,4.751C3.27,5.357,3.067,6.062,3.067,6.814 c0,1.424,0.724,2.679,1.825,3.415c-0.673-0.021-1.305-0.206-1.859-0.513c0,0.017,0,0.034,0,0.052c0,1.988,1.414,3.647,3.292,4.023 c-0.344,0.094-0.707,0.144-1.081,0.144c-0.264,0-0.521-0.026-0.772-0.074c0.522,1.63,2.038,2.816,3.833,2.85 c-1.404,1.1-3.174,1.756-5.096,1.756c-0.331,0-0.658-0.019-0.979-0.057c1.816,1.164,3.973,1.843,6.29,1.843 c7.547,0,11.675-6.252,11.675-11.675c0-0.178-0.004-0.355-0.012-0.531C20.985,7.47,21.68,6.747,22.23,5.924z"></path></svg>
                  </a>
              </p>*/}
            </div>
            <div className="column">
              <div className="block-image"><figure><a href="https://twitter.com/jeffSARRIS" target="_blank" rel="noreferrer"><img src="https://badfroot.com/wp-content/uploads/sites/11/2022/01/Jeff-Sarris.jpg" alt="" width="375" height="375" /></a></figure></div>
              <h4 id="jeff-sarris">Jeff Sarris</h4>
                <p><em>Brand Strategy/Developer</em></p>
                <p>As cofounder of <a href="https://spyr.me" target="_blank" rel="noreferrer">SPYR</a>, Jeff’s spent the last decade-plus behind the scenes helping entrepreneurs develop their brands and businesses. His most recognized work is with The Minimalists and on the Netflix documentary, <em><a href="https://www.netflix.com/title/80114460" target="_blank" rel="noreferrer">Minimalism</a></em>. He’s also the host of several podcasts: <strong>Starting Now</strong>, where he dives into NFT origin stories, <strong>Kidney Stone Diet</strong>, and <strong>Relentless</strong>, a weekly live show where he and Jack dive into what it takes to make it as an entrepreneur in the wild west of NFTs. Watch on YouTube or listen wherever you get your podcasts.</p>
                {/*<p>
                    <a href="https://twitter.com/jeffSARRIS" aria-label="" rel="noreferrer" target="_blank" className="wp-block-social-link-anchor">
                      <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" focusable="false"><path d="M22.23,5.924c-0.736,0.326-1.527,0.547-2.357,0.646c0.847-0.508,1.498-1.312,1.804-2.27 c-0.793,0.47-1.671,0.812-2.606,0.996C18.324,4.498,17.257,4,16.077,4c-2.266,0-4.103,1.837-4.103,4.103 c0,0.322,0.036,0.635,0.106,0.935C8.67,8.867,5.647,7.234,3.623,4.751C3.27,5.357,3.067,6.062,3.067,6.814 c0,1.424,0.724,2.679,1.825,3.415c-0.673-0.021-1.305-0.206-1.859-0.513c0,0.017,0,0.034,0,0.052c0,1.988,1.414,3.647,3.292,4.023 c-0.344,0.094-0.707,0.144-1.081,0.144c-0.264,0-0.521-0.026-0.772-0.074c0.522,1.63,2.038,2.816,3.833,2.85 c-1.404,1.1-3.174,1.756-5.096,1.756c-0.331,0-0.658-0.019-0.979-0.057c1.816,1.164,3.973,1.843,6.29,1.843 c7.547,0,11.675-6.252,11.675-11.675c0-0.178-0.004-0.355-0.012-0.531C20.985,7.47,21.68,6.747,22.23,5.924z"></path></svg>
                    </a>
                  <a href="https://youtube.com/jeffsarris" aria-label="YouTube: https://youtube.com/jeffsarris" rel="noreferrer" target="_blank" className="wp-block-social-link-anchor">
                      <svg width="24" height="24" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" focusable="false"><path d="M21.8,8.001c0,0-0.195-1.378-0.795-1.985c-0.76-0.797-1.613-0.801-2.004-0.847c-2.799-0.202-6.997-0.202-6.997-0.202 h-0.009c0,0-4.198,0-6.997,0.202C4.608,5.216,3.756,5.22,2.995,6.016C2.395,6.623,2.2,8.001,2.2,8.001S2,9.62,2,11.238v1.517 c0,1.618,0.2,3.237,0.2,3.237s0.195,1.378,0.795,1.985c0.761,0.797,1.76,0.771,2.205,0.855c1.6,0.153,6.8,0.201,6.8,0.201 s4.203-0.006,7.001-0.209c0.391-0.047,1.243-0.051,2.004-0.847c0.6-0.607,0.795-1.985,0.795-1.985s0.2-1.618,0.2-3.237v-1.517 C22,9.62,21.8,8.001,21.8,8.001z M9.935,14.594l-0.001-5.62l5.404,2.82L9.935,14.594z"></path></svg>
                    </a>
                </p>*/}
            </div>
          </div>
        </div>
        </div>
        <div className="site-footer">
          <div className="wrap">
            &copy;{new Date().getFullYear()} <a href="https://badfroot.com" rel="noreferrer" target="_blank">Badfroot</a><br />
            NFT Development by <a href="https://ryps.co" rel="noreferrer" target="_blank">RYPS</a>
          </div>
        </div>
         {/* Admin */}
         {false ? renderAdminButtons : undefined}
      </div>
    </div>
  );
};

export default App;